import Http from '/src/lib/http';

export default {
    async getDashboardStatus(context) {
        const response = await Http.get('temp/user-data');

        if (response) {
            var dbsCheckAdult = false;
            var dbsCheckChild = false;
            var dbsRisk = false;
            var healthRisk = false;
            var rightToWork = false;
            var idBadge = false;
            var successfulInterview = false;
            var references = false;

            if (response.statuses.dbsCheckAdult) {
                dbsCheckAdult = true;
            }
            if (response.statuses.dbsCheckChild) {
                dbsCheckChild = true;
            }
            if (response.statuses.dbsRiskAssessment) {
                dbsRisk = true;
            }
            if (response.statuses.healthRiskAssessment) {
                healthRisk = true;
            }
            if (response.statuses.rightToWork) {
                rightToWork = true;
            }
            if (response.statuses.idBadge) {
                idBadge = true;
            }
            if (response.statuses.successfulInterview) {
                successfulInterview = true;
            }
            if (response.statuses.references) {
                references = true;
            }

            context.commit('setDashboardStatus', {
                dbsCheckAdult: dbsCheckAdult,
                dbsCheckChild: dbsCheckChild,
                dbsRisk: dbsRisk,
                healthRisk: healthRisk,
                rightToWork: rightToWork,
                idBadge: idBadge,
                successfulInterview: successfulInterview,
                references: references,
                shiftDistance: response.shiftDistance,
                isSirenumCompliant: response.isSirenumCompliant,
            });

            return true;    //Required for return
        }
    },
    async getUserData(context) {
        const response = await Http.get('temp/user-data');

        await context.dispatch('setUserData', response, { root: true });

        return response;
    },
    async updateUserData(context, payload) {
        await Http.post('temp/update-profile', {
            first_name: payload.firstName,
            last_name: payload.lastName,
            mobile_number: payload.mobileNumber,
            email: payload.email,
            street: payload.street,
            town: payload.town,
            county: payload.county,
            postcode: payload.postcode,
        });

        await context.dispatch('profile/getUserData', null, { root: true });
    },
    async getTempCoins() {
        return await Http.get('coin-action/list');
    },
    async getTempCoinsCategorised() {
        return await Http.get('coin-action/categorised');
    },
    async getTrainingModules() {
        return await Http.get('online-training-modules/list');
    },
    async updateTraingStatus(context, payload) {
        const userId = context.rootGetters.userId;
        const url = 'https://temprewardsadmin.stage.ascensor.co.uk/api/online-training-update';

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user_id: userId,
                online_training_id: payload.traingId,
                status: true,
            }),
        });

        const responseData = await response.json();

        if (responseData.error) {
            const error = new Error(responseData.error || 'Failed to authenticate, try again!');
            throw error;
        }

        if (responseData.success) {
            const extractedData = responseData.success;

            return extractedData;
        }
    },
    async fetchReferList() {
        return await Http.get('temp/referrals');
    },
    async fetchTrainingLocation() {
        return await Http.get('practical-training/locations');
    },
    async savePracticalLocation(context, payload) {
        const response = await Http.get(`practical-training/locations/${payload.locationId}/dates`);

        if (response) {
            context.commit('savePracticalLocation', {
                location: payload.location,
                locationId: payload.locationId,
            });

            return response;
        }
    },
    savePracticalDate(context, payload) {
        context.commit('savePracticalDate', {
            selectedDateId: payload.selectedDateId,
            selectedDate: payload.selectedDate
        });
    },
    async submitPracticalTrainingData(context) {
        const dateId = context.getters.practicalDateId;

        return await Http.post(`practical-training/date/${dateId}/select`);
    },
    async fetchPracticalTrainingData() {
        return await Http.get('practical-training/status');
    },
    async cancelPracticalTraining(context, payload) {
        const userId = context.rootGetters.userId;
        const url = 'https://temprewardsadmin.stage.ascensor.co.uk/api/cancel_practical_training';
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user_id: userId,
                practical_training_id: payload.trainingId
            }),
        });

        const responseData = await response.json();
        if (responseData.error) {
            const error = new Error(responseData.error || 'Failed to authenticate, try again!');
            throw error;
        }

        if (responseData.success) {
            const extractedData = responseData.success;
            return extractedData;
        }
    },
    async saveNotification(context, payload) {
        await Http.post('temp/notification-preferences', payload);
    },
}