
export default {
    setDate(state, payload) {
        state.shiftDates = [];
        payload.shiftDate.map((data) => {
            /* console.log(data);
            console.log(new Date(data)); */
            const dateObj = {
                'date': new Date(data),
                'datestring': data,
            }
            state.shiftDates.push(dateObj);
        });
    },
    setShiftType(state, payload) {
        state.earlyShift = payload.earlyShift;
        state.lateShift = payload.lateShift;
        state.nightShift = payload.nightShift;
        state.sleepInShift = payload.sleepInShift;
    },
    setShiftTypeAdditional(state, payload) {
        state.sleepInShiftAdditional = payload.sleepInShiftAdditional;
        state.emergencyOpp = payload.emergencyOpp;
    },
    clearStoreGetters(state) {
        state.shiftDates = [];
        state.earlyShift = false;
        state.lateShift = false;
        state.nightShift = false;
        state.sleepInShift = false;
        state.sleepInShiftAdditional = false;
        state.emergencyOpp = false;
    }
};