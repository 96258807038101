<template>
  <ion-app>
    <ion-menu side="start" menu-id="first" content-id="main" class="main-menu">
      <section class="container menu-container">
        <div class="menu-close-icon">
          <a menuClose="left" v-on:click="menuClose"
            ><img src="./theme/images/menu-close-icon.svg" alt="TempRewards"
          /></a>
        </div>
        <div class="menu-option-outr">
          <ul>
            <li>
              <a @click="goToPage('/dashboard')">
                Dashboard
                <i class="pull-right"
                  ><img
                    src="./theme/images/submit-orange-arrow.svg"
                    alt="TempRewards"
                /></i>
              </a>
            </li>
            <li>
              <a @click="goToPage('/my-schedule')">
                Manage shifts
                <i class="pull-right"
                  ><img
                    src="./theme/images/submit-orange-arrow.svg"
                    alt="TempRewards"
                /></i>
              </a>
            </li>
            <li>
              <a @click="goToPage('/online-training')">
                Training
                <i class="pull-right"
                  ><img
                    src="./theme/images/submit-orange-arrow.svg"
                    alt="TempRewards"
                /></i>
              </a>
            </li>
            <li>
              <a @click="goToPage('/profile')">
                Profile
                <i class="pull-right"
                  ><img
                    src="./theme/images/submit-orange-arrow.svg"
                    alt="TempRewards"
                /></i>
              </a>
            </li>
            <li>
              <a @click="goToPage('/recommend-a-friend')">
                Refer a friend
                <i class="pull-right"
                  ><img
                    src="./theme/images/submit-orange-arrow.svg"
                    alt="TempRewards"
                /></i>
              </a>
            </li>
            <li>
              <a @click="goToPage('/how-to-earn-tempcoins')">
                How to earn TempCoins
                <i class="pull-right"
                  ><img
                    src="./theme/images/submit-orange-arrow.svg"
                    alt="TempRewards"
                /></i>
              </a>
            </li>
          </ul>
        </div>
        <div class="menu-logout">
          <a @click="logout"
            ><i
              ><img src="./theme/images/logout-icon.svg" alt="TempRewards"
            /></i>
            Log out
          </a>
        </div>
      </section>
    </ion-menu>
    <ion-router-outlet id="main" />
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet, IonMenu, menuController } from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
    IonMenu,
  },
  methods: {
    menuClose() {
      menuController.close();
    },
    logout() {
      menuController.close();
      this.$store.dispatch("logout");
      this.$router.push({ name: 'sign-in' });
    },
    goToPage(routeName) {
      menuController.close();
      const currentRouteName = this.$router.currentRoute.value.fullPath;
      if (routeName != currentRouteName) {
        this.$router.push(routeName);
      } 
    },
  },
});
</script>