export default {
    setUser(state, payload) {
        Object.assign(state, payload);
    },
    resetUser(state) {
        state.id = null,
        state.code = null,
        state.firstName = null,
        state.lastName = null,
        state.mobileNumber = null,
        state.email = null,
        state.street = null,
        state.town = null,
        state.county = null,
        state.postcode = null,
        state.verified = false,
        state.image = '';
        state.referralCode = null;
        state.coinsBalance = 0;
        state.coinsPending = 0;
    },
    storeReferralCode(state, payload){
        state.referralCode = payload.referralCode;
    }
};