class Http {
    async doRequest(method, endpoint, data) {
        const isFile = Object.prototype.toString.call(data) === '[object FormData]';

        const headers = {
            'Accept': 'application/json',
            'Authorization': `Bearer ${this.token}`,
        };

        const params = {
            method,
            headers
        };

        if (!isFile) {
            headers['Content-Type'] = 'application/json';
        }

        if (data) {
            params.body = isFile ? data : JSON.stringify(data);
        }

        const response = await fetch(`${process.env.VUE_APP_API_URL}${endpoint}`, params);

        if ([404, 500].includes(response.status)) {
            throw Error('There was an issue with the request');
        }

        const json = await response.json();

        if (json.status === 'error' || json.error || json.errors) {
            if (json.messages) {
                let error = new Error;

                error.messages = json.messages;

                throw error;
            } else {
                throw Error(json.message);
            }
        }

        return json.data || true;
    }

    setToken(token) {
        this.token = token;
    }

    async get(endpoint, params) {
        endpoint += '?'+new URLSearchParams(params);

        return this.doRequest('GET', endpoint);
    }

    async post(endpoint, data) {
        return this.doRequest('POST', endpoint, data);
    }
}

const http = new Http();

export default http;
