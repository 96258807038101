//Import Vue Cookies
import VueCookies from 'vue-cookies';

//Import the router
import { createRouter, createWebHistory } from '@ionic/vue-router';
import HomePage from '../views/home/HomePage.vue';
import store from '../store/index.js';
import { Storage } from '@capacitor/storage';
import Http from '/src/lib/http';

//Setup routes
const routes = [
  {
    name: 'root',
    path: '/',
    redirect: '/home'
  },
  {
    name: 'home',
    path: '/home',
    component: HomePage,
  },
  {
    name: 'privacy-policy',
    path: '/pages/privacy-policy',
    component: () => import('../views/pages/PrivacyPolicy.vue'),
  },
  {
    name: 'cookie-policy',
    path: '/pages/cookie-policy',
    component: () => import('../views/pages/CookiePolicy.vue'),
  },
  {
    name: 'terms-and-conditions',
    path: '/pages/terms-and-conditions',
    component: () => import('../views/pages/TermsConditions.vue'),
  },
  {
    name: 'tempcoins-info',
    path: '/what-are-temp-coins',
    component: () => import('../views/home/WhatAreTempPage.vue'),
  },
  {
    name: 'getting-started',
    path: '/getting-started',
    component: () => import('../views/home/GettingStartedPage.vue'),
  },
  {
    name: 'sign-up',
    path: '/sign-up',
    component: () => import('../views/registration/RegistrationPage.vue'),
    meta: { requiresUnauth: true },
  },
  {
    name: 'sign-up-verify',
    path: '/sign-up/verify',
    component: () => import('../views/registration/RegistrationVerifyPage.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'sign-up-change-mobileno',
    path: '/sign-up/change-mobileno',
    component: () => import('../views/registration/ChangeMobileNo.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'sign-in',
    path: '/sign-in',
    component: () => import('../views/registration/SigninPage.vue'),
    meta: { requiresUnauth: true },
  },
  {
    name: 'reset-password',
    path: '/reset-password',
    component: () => import('../views/registration/ResetPasswordPage.vue'),
    meta: { requiresUnauth: true },
  },
  {
    name: 'reset-password-success',
    path: '/reset-password/success',
    component: () => import('../views/registration/ResetPasswordConfirmPage.vue'),
    meta: { requiresUnauth: true },
  },
  {
    name: 'complete-profile-address',
    path: '/complete-profile-address',
    component: () => import('../views/complete_profiile/AddressPostcodePage.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'profile-picture',
    path: '/profile-picture',
    component: () => import('../views/complete_profiile/ProfilePicture.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'dashboard',
    path: '/dashboard',
    component: () => import('../views/dashboard/DashboardPage.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'rewards-single',
    path: '/rewards/:id',
    component: () => import('../views/rewards/RewardsDetails.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'rewards-single-purchase',
    path: '/rewards/:id/purchase',
    component: () => import('../views/rewards/RewardsPurchase.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'other-app',
    path: '/other-app',
    component: () => import('../views/dashboard/OtherApp.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'tempcoins-how-to-ear',
    path: '/how-to-earn-tempcoins',
    component: () => import('../views/dashboard/HowToEarn.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'profile',
    path: '/profile',
    component: () => import('../views/profile/ProfilePage.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'notification-manager',
    path: '/profile/notification-manager',
    component: () => import('../views/profile/NotificationManager.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'online-training',
    path: '/online-training',
    component: () => import('../views/training/OnlineTraing.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'practical-training',
    path: '/practical-training',
    component: () => import('../views/training/PracticalTraining.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'practical-training-location',
    path: '/practical-training-location',
    component: () => import('../views/training/PracticalTrainingLocation.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'practical-training-confirmation',
    path: '/practical-training-confirmation',
    component: () => import('../views/training/PracticalTrainingConfirmation.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'practical-training-booked',
    path: '/practical-training-booked',
    component: () => import('../views/training/PracticalTrainingBooked.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'practical-training-cancelled',
    path: '/practical-training-cancelled',
    component: () => import('../views/training/PracticalTrainingCancelled.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'recommend-a-friend',
    path: '/recommend-a-friend',
    component: () => import('../views/refer/ReferFriend.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'my-schedule',
    path: '/my-schedule',
    component: () => import('../views/shift/MySchedule.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'my-availability',
    path: '/my-availability',
    component: () => import('../views/shift/MyAvailability.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'my-availability-shift-types',
    path: '/my-availability/shift-types',
    component: () => import('../views/shift/ShiftType.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'my-availability-shift-types-additional',
    path: '/my-availability/shift-types-additional',
    component: () => import('../views/shift/ShiftTypeAdditional.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'my-availability-travel-distance',
    path: '/my-availability/travel-distance',
    component: () => import('../views/shift/ShiftDistance.vue'),
    meta: { requiresAuth: true },
  },
  {
    name: 'available-shifts',
    path: '/available-shifts',
    component: () => import('../views/shift/AvailableShift.vue'),
    meta: { requiresAuth: true },
  },

  //For development. A place to test things.
  {
    name: 'dev',
    path: '/pages/dev',
    component: () => import('../views/pages/DevTest.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (/*to, from, savedPosition*/) {
    // console.log({to, from, savedPosition});

    /*
      //Unable to use this as Ionic is using a position absolute scrolling method
      //https://ionicframework.com/docs/api/content#methods

      //Default - top position
      var position = { top: 0 }

      //DASHBOARD: If going to the Dashboard return the default position (top of page)
      if (to.name == 'dashboard') {
        return position;

      //If the position is saved - Default page navigation style
      } else if (savedPosition) {
        return savedPosition;

      //Anything else, return the default
      } else {
        return new Promise((resolve,) => {
          setTimeout(() => {
            resolve({ left: 0, top: 0 })
          }, 500)
        })
      }
    */
 
  }
});

//Before each route
router.beforeEach(async function (to, from) {
  // await Storage.configure({group: 'temprewards'});
  const token = await Storage.get({key: 'token'});

  Http.setToken(token.value);

  //Variable
  const isLoggedId = store.getters.isAuthenticated;

  //If the user is not authenticated
  if (!isLoggedId) {

    //If page requires login
    if (to.meta.requiresAuth) {

      //If coming from the Getting Started page
      if (from.name == 'getting-started') {
        return { name: 'sign-up' };
      }

      //Else any other page which requires Auth, go to the login page
      return { name: 'sign-in' };
    }

    //Determine if Welcome Screens should be skipped - If `hideWelcome` exists
    const welcomeScreens = VueCookies.isKey('hideWelcome');
    if (to.name == 'root' && welcomeScreens || to.name == 'home' && welcomeScreens) {
      //Go to the sign in page
      return { name: 'sign-in' };
    }

    //Go to the requested page
    return true;


  //If the user is already authenticated
  } else if (isLoggedId) {
    //User is logged in so set cookie to note the welcome screens can be hidden
    //VueCookies.set('hideWelcome', '1', '12m');//12 month cookie

    //If accessing certain pages
    if (
      to.name == 'root' ||
      to.name == 'home' ||
      to.name == 'tempcoins-info' ||
      to.name == 'getting-started' ||
      to.name == 'sign-up' ||
      to.name == 'sign-in'
    ) {
      //Go to dashboard - show Welcome Loading Screen
      return { name: 'dashboard', query: { welcomeLoader: "show" } };
    }

    //Variables
    const isVerified = store.getters.isVerified;
    const isAddressComplete = store.getters.isAddressComplete;
    //const isImage = store.getters.pictureStatus;

    //If not verified
    if (!isVerified) {
      //If user has gone to the Change Mobile No page from the Sign Up Verification page
      if (to.name == 'sign-up-change-mobileno' && from.name == 'sign-up-verify') {
        //Do nothing - we allow going to this page

      //If user is going to something other than the Sign Up Verification page then redirect
      } else if (to.name != 'sign-up-verify') {
        return { name: 'sign-up-verify' };
      }
    }

    //If address is not complete but account is verified
    if (isVerified && !isAddressComplete && to.name != 'complete-profile-address') {
      return { name: 'complete-profile-address' };
    }

    //If no profile image, but account is verified and has address
    //Allow this to be skipped - The profile photo page is shown after confirming an address on `complete-profile-address`
    /*if (isVerified && isAddress && !isImage && to.name != 'profile-picture') {
      console.log("No profile image");
      return { name: 'profile-picture' };
    }*/

    //Go to the requested page
    return true;


  //Anything else, catch-all
  } else {
    return { name: 'sign-in' };
  }
});

export default router
