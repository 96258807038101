<template>
	<div class="" v-if="isOpen && elementVisible" :class="cssClass">
		<section class="container no-pad-container">
			<div class="icon temp-rewards-icon-large"></div>
			<span class="loading-text" v-if="message">{{ message }}</span>
		</section>
	</div>
</template>
  
<script>
  export default {
	//Components
	components: {
	},
	
	//Props
	props: [
		"isOpen",
		"cssClass",
		"message",
		"duration"
	],

	//Data
	data() {
		return {
			elementVisible: true
		};
	},

	mounted() {
		//If duration is set
		if (this.duration > 0) {
			setTimeout(() => this.elementVisible = false, this.duration)
		}
	},

	//Computed
	computed: {
	},

	//Methods
	methods: {
	},
  }
</script>