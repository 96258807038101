import Http from '/src/lib/http';

export default {
    async findaddress(context, payload) {
        return Http.get('temp/postcode-lookup', {
            postcode: payload.postcode
        });
    },

    async submitaddress(context, payload) {
        const addressType = payload.manualaddress;

        const data = (addressType) ? {
            street: `${payload.addressOne}, ${payload.addressTwo}`,
            town: payload.town,
            county: payload.county,
            postcode: payload.postcode,
        } : {
            street: payload.address.street,
            town: payload.address.town,
            county: payload.address.county,
            postcode: payload.postcode,
            latitude: payload.latitude,
            longitude: payload.longitude,
        };

        await Http.post('temp/update-address', data);

        await context.dispatch('profile/getUserData', null, { root:true });
    },

    // profile image upload
    async uploadprofileimg(context, payload) {
        let formData = new FormData();

        formData.append('image', payload.image);

        await Http.post('temp/upload-image', formData);
    },
};
