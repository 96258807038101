import Http from '/src/lib/http';

export default {
    async getRewardsList() {
        return await Http.get('reward/list');
    },
    async getRewardsDetails(context, payload){
        return await Http.get(`reward/${payload.id}`);
    },
    async confirmPurchase(context, payload){
        await Http.post(`reward/${payload.id}/purchase`);
    },
    async confirmNotify(/*context, payload*/){
        throw Error('Not yet implemented');
        /*const userId = context.rootGetters.userId;
        const url = 'https://temprewardsadmin.stage.ascensor.co.uk/api/reward_notify';


        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user_id: userId,
                rewards_id: payload.id,
            }),
        });

        const responseData = await response.json();
        
        if (responseData.error) {
            const error = new Error(responseData.error || 'Failed to authenticate, try again!');
            throw error;
        }

        if (responseData.success) {
            const extratedData = responseData.success;
            return extratedData;
        }*/
    },

}