export default {
    shiftDates(state) {
        return state.shiftDates;
    },
    earlyShift(state) {
        return state.earlyShift;
    },
    lateShift(state) {
        return state.lateShift;
    },
    nightShift(state) {
        return state.nightShift;
    },
    sleepInShift(state) {
        return state.sleepInShift;
    },
    sleepInShiftAdditional(state) {
        return state.sleepInShiftAdditional;
    },
    emergencyOpp(state) {
        return state.emergencyOpp;
    },
    isViewingCalendarShift(state) {
        return state.viewingShiftInCalendar;
    }
};