import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
    namespaced: true,
    state() {
        return {
            viewingShiftInCalendar: false,
            shiftDates: [],
            earlyShift: false,
            lateShift: false,
            nightShift: false,
            sleepInShift: false,
            sleepInShiftAdditional: false,
            emergencyOpp: false,
        };
    },
    mutations,
    actions,
    getters,
}