// Custom validation plugin
import {
	Form as VeeForm, Field as VeeField, defineRule,
	ErrorMessage, configure,
  } from 'vee-validate'; // Rename items 'as' to avoid name clashes with components
  import {
	required, min, max, alpha_spaces as alphaSpaces, email, digits, confirmed, not_one_of as excluded, min_value as minVal, max_value as maxVal,
  } from '@vee-validate/rules'; // Include rules fromt he Vee Validate Rules package
  
  export default {
	// Vue will always run this install plugin by default
	// App: references the Vue instance. Options: Additional data passed by Vue in main.js
	// install(app, options) {
	install(app) {
		// console.log(options);
  
		app.component('VeeForm', VeeForm); // This component will allow VeeForm to use used
		app.component('VeeField', VeeField); // This component will allow VeeField to use used
  
		app.component('ErrorMessage', ErrorMessage); // This component will output the error message
  
		// Name, Function name - but we're already using the 'required' function included in VeeValidate
		defineRule('required', required);
  
		// Define other rules
		defineRule('min', min);
		defineRule('max', max);
		defineRule('alphaSpaces', alphaSpaces);
		defineRule('email', email);
		defineRule('digits', digits);
		defineRule('passwords_mismatch', confirmed);
		defineRule('min_value', minVal);
		defineRule('max_value', maxVal);
		defineRule('excluded', excluded);
		defineRule('country_excluded', excluded);
		defineRule('form_terms', required);
  
		// Configure allows us to override the default rule values
		configure({
			generateMessage: (ctx) => {
				// Custom rule validation messages
				//${ctx.field} - Use in the message for the field name
				const messages = {
				required: `This field is required.`,
				min: `This field is too short.`,
				max: `This field is too long.`,
				alpha_spaces: `This field may only contain alphabetical characters and symbols.`,
				email: `This field must be a valid email.`,
				min_value: `This field is too low.`,
				max_value: `This field is too high.`,
				excluded: `You are not allowed to use this value for this field.`,
				country_excluded: `Due to restrications, we do not accept users from ${ctx.field}.`,
				passwords_mismatch: 'The passwords don\'t match.',
				form_terms: 'You must accept the Terms of Service.',
			};
  
			const message = messages[ctx.rule.name] ? messages[ctx.rule.name] : `This field is invalid.`;
  
			return message;
		},
		validateOnBlur: true,
		validateOnChange: true,
		validateOnInput: false,
		validateOnModelUpdate: true,
		});
	},
  };
  