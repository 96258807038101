<template>
  <ion-page>

    <!-- Welcome Screen loader (custom component), duration e.g: 1000  -->
    <welcome-screen
      :is-open="showWelcomeLoader"
      cssClass="loading-welcome-screen"
      message=""
      duration=false
    >
    </welcome-screen>
    <!-- Checks complete -->
    <section class="dashboard-overlay dashboard-overlay-scroll" v-if="allCheck">
      <div class="overlay-content-wrapper dashboard-12-content-outr dashboard-13-content-outr">
        <div class="close-icon">
          <a v-on:click="hideStatusComplete"
            ><i><img src="../theme/images/close-icon-white.svg" /></i
          ></a>
        </div>

        <div class="congratulation-logo">
          <img src="../theme/images/badge.svg" alt="TempRewards" />
        </div>
        <div class="congratulation-heading text-center">
          <h3>Congratulations, You're ready to work</h3>
        </div>
        <div class="congratulation-txt text-center">
          <p>
            You are now fully compliant and ready to work. Remember, you can
            earn TempCoins for every shift you book!
          </p>
          <p>You have earned 250 TempCoins so far!</p>
        </div>
        <div class="congratulation-btn-outr">
          <a class="btn btn-block take-btn" v-on:click="hideStatusComplete" @click="goToPage('/my-schedule')">
            Take me to Available Shifts
            <img
              src="../theme/images/submit-white-arrow.svg"
              alt="TempRewards"
              class="pull-right-share"
            />
          </a>
        </div>
      </div>
    </section>

    <!-- Ion Loading (default) -->
    <ion-loading
      :is-open="showLoader"
      cssClass="loading-default"
      message="Please wait..."
    >
    </ion-loading>

    <!-- Custom error modal -->
    <error-modal
      :errorArgs="errorModalArgs"
    >
    </error-modal>

    <!-- Ion Content -->
    <ion-content class="ion-content-wrapper">
      <section class="container dashbord-container no-pad-container">
        <div class="dashboard-header">
          <ion-buttons class="ion-no-margin ion-no-padding menu-button">
            <ion-menu-button
              menu="first"
              id="main"
              auto-hide="false"
            ></ion-menu-button>
          </ion-buttons>
          <div class="dashboard-header-num">
            <span class="gold-text pad-right-5" v-if="tempCoinsPending">({{ tempCoinsPending }} pending)</span>

            <h3>{{ tempCoinAvailable }}</h3>
            <i><img src="../theme/images/temp-logo.svg" alt="TempRewards" /></i>
          </div>
        </div>
        <div class="dashboard-txt-outr">
          <h3>Hi {{ userName }}</h3>
          <p v-html="displayText"></p><!-- From component attribute - `DashboardPage.vue`-->
        </div>

        <!-- Content is displayed here -->
        <slot />

        
      </section>
    </ion-content>

    <ion-footer class="dash-footer">
      <div class="dashboard-footr">
        <div class="dashboard-footr-grid">
          <a @click="goToPage('/my-schedule')">
            <div class="dashboard-footr-grid-img shifts" :class="this.availableShifts ? 'indicator' : '' ">
              <img
                src="../theme/images/shifts.svg"
                alt="TempRewards"
                v-if="!isShift"
              />
              <img
                src="../theme/images/shifts-hover.svg"
                alt="TempRewards"
                v-if="isShift"
              />
            </div>
            <h3 :class="isShift ? 'footer-active' : ''">shifts</h3>
          </a>
        </div>
        <div class="dashboard-footr-grid">
          <a @click="goToPage('/online-training')">
            <div class="dashboard-footr-grid-img training">
              <img
                v-if="!isTraining"
                src="../theme/images/train.svg"
                alt="TempRewards"
              />
              <img
                v-if="isTraining"
                src="../theme/images/train-hover.svg"
                alt="TempRewards"
              />
            </div>
            <h3 :class="isTraining ? 'footer-active' : ''">train</h3>
          </a>
        </div>
        <div class="dashboard-footr-grid dashboard-footr-logo-grid">
          <a @click="goToPage('/dashboard');"><!-- goToPage('/dashboard');scrollToTop(); -->
            <div class="dashboard-footr-logo-outr" style="position: relative">
              <div class="dashboard-footer-logo-absolute">
                <img
                  src="../theme/images/dashboard-footer-logo.png"
                  alt="TempRewards"
                  width="100"
                />
              </div>
            </div>
          </a>
        </div>
        <div class="dashboard-footr-grid">
          <a @click="goToPage('/profile')">
            <div class="dashboard-footr-grid-img profile">
              <img
                v-if="!isProfile"
                src="../theme/images/profile.svg"
                alt="TempRewards"
              />
              <img
                v-if="isProfile"
                src="../theme/images/profile-hover.svg"
                alt="TempRewards"
              />
            </div>
            <h3 :class="isProfile ? 'footer-active' : ''">profile</h3>
          </a>
        </div>
        <div class="dashboard-footr-grid">
          <a @click="goToPage('/recommend-a-friend')">
            <div class="dashboard-footr-grid-img refer">
              <img
                src="../theme/images/refer.svg"
                alt="TempRewards"
                v-if="!isRefer"
              />
              <img
                src="../theme/images/refer-hover.svg"
                alt="TempRewards"
                v-if="isRefer"
              />
            </div>
            <h3 :class="isRefer ? 'footer-active' : ''">refer</h3>
          </a>
        </div>
      </div>
    </ion-footer>
  </ion-page>
</template>

<script>
import WelcomeScreen from "./WelcomeScreenLoader.vue";

import {
  IonPage,
  IonContent,
  IonButtons,
  IonMenuButton,
  IonLoading,
  IonFooter,
} from "@ionic/vue";

export default {
  //Components
  components: {
    "welcome-screen": WelcomeScreen,
    IonPage,
    IonContent,
    IonButtons,
    IonMenuButton,
    IonLoading,
    IonFooter,
  },

  //Props
  props: [
    "userName",
    "displayText",
    "showLoader",
    "showWelcomeLoader",
    "errorModalArgs",
    "allCheck",
    "hideStatusComplete",
    "dashbordPoint",
    "dashbordPending",
  ],

  data: () => ({
    availableShifts: false, //Default,
    showDetailsOverlay: false
  }),

  //Watcher
  watch: {
    availableShifts: function () {
      //Do somthing on the watch
    },
  },

  //Computed properties
  computed: {
    isViewingCalendarShift() {
      return this.$store.getters['shifts/isViewingCalendarShift']
    },
    isShift() {
      const currentRoute = this.$router.currentRoute.value.fullPath;

      if (
        currentRoute == "/my-schedule" ||
        currentRoute == "/available-shifts" ||
        currentRoute == "/my-availability" ||
        currentRoute == "/my-availability/shift-types" ||
        currentRoute == "/my-availability/shift-types-additional" ||
        currentRoute == "/my-availability/travel-distance"
      ) {
        return true;
      } else {
        return false;
      }
    },
    isProfile() {
      const currentRoute = this.$router.currentRoute.value.fullPath;
      if (
        currentRoute == "/profile" ||
        currentRoute == "/profile/notification-manager"
      ) {
        return true;
      } else {
        return false;
      }
    },
    isTraining() {
      const currentRoute = this.$router.currentRoute.value.fullPath;
      if (currentRoute == "/online-training") {
        return true;
      } else {
        return false;
      }
    },
    isRefer() {
      const currentRoute = this.$router.currentRoute.value.fullPath;
      if (currentRoute == "/recommend-a-friend") {
        return true;
      } else {
        return false;
      }
    },
    tempCoinAvailable() {
      const coinAAvailable = this.$store.getters["coinsBalance"];
      if (coinAAvailable > 0) {
        return coinAAvailable;
      } else {
        return 0;
      }
    },
    tempCoinsPending() {
      const coinsPending = this.$store.getters["coinsPending"];
      if (coinsPending > 0) {
        return coinsPending;
      } else {
        return 0;
      }
    },
  },

  //Methods
  methods: {
    removeShiftTooltipOverlay() {
      this.$store.commit('shifts/setViewingShiftInCalendar', false);
    },
    async goToPage(routeName) {
      const currentRouteName = this.$router.currentRoute.value.fullPath;
      if (routeName != currentRouteName) {
        await this.$router.push(routeName);
        this.scrollToTop();
      }
    },

    scrollToTop() {
      const scrollContent = document.querySelectorAll('ion-content.ion-content-wrapper');

      for (var i = 0; i < scrollContent.length; i++) {
        scrollContent[i].scrollToTop(150) // duration: 150ms
      }
    },
  },

  async updated() {
    // this.scrollToTop();

  },

  //Async Before Create
  async beforeCreate() {
    try {
      const response = await this.$store.dispatch("shifts/getAvailableShift");
      if (response.length) {
        this.availableShifts = true;
      }

    } catch (error) {
      console.log(error)
    }
  }
};
</script>

<style>
.gold-text {
  color: #fec031;
}
.pad-right-5 {
  padding-right: 5px;
}

.shift-tooltip-overlay {
  top: 0;
  left: 0;
  position:absolute;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.9);
  z-index:90;
}
</style>