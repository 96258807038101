import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
    state() {
        return {
            id: null,
            code: null,
            firstName: null,
            lastName: null,
            mobileNumber: null,
            email: null,
            street: null,
            town: null,
            county: null,
            postcode: null,
            verified: false,
            image: '',
            referralCode: null,
            coinsBalance: 0,
            coinsPending: 0,
            notificationsEmail: true,
            notificationsSms: true,
            notificationsPush: true,
        };
    },
    mutations,
    actions,
    getters,
}