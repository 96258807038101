import Http from '/src/lib/http';

export default {
    async getMyAvailability() {
        return await Http.get('temp/availability/dates');
    },
    async saveAvailability(context, payload) {

        const selectedDates = payload.dates;

        context.commit("setDate", {
            shiftDate: selectedDates,
        });
        /* const extratedData = responseData.success; */
        return true;

    },
    async saveSfitType(context, payload) {

        const earlyShift = payload.earlyShift;
        const lateShift = payload.lateShift;
        const nightShift = payload.nightShift;
        const sleepInShift = payload.sleepInShift;

        context.commit("setShiftType", {
            earlyShift: earlyShift,
            lateShift: lateShift,
            nightShift: nightShift,
            sleepInShift: sleepInShift,
        });
        return true;
    },
    async saveSfitTypeAdditional(context, payload) {

        const sleepInShiftAdditional = payload.sleepInShiftAdditional;
        const emergencyOpp = payload.emergencyOpp;

        context.commit("setShiftTypeAdditional", {
            sleepInShiftAdditional: sleepInShiftAdditional,
            emergencyOpp: emergencyOpp,

        });
        return true;
    },
    async storeMyAvailabilitytoDB(context, payload) {
        const response = await Http.post('temp/availability/dates', {
            available_dates: context.getters.shiftDates.map(data => data.datestring),
            // early_shift: context.getters.earlyShift,
            // late_shift: context.getters.lateShift,
            // night_shift: context.getters.nightShift,
            // sleep_in_shift: context.getters.sleepInShift,
            // add_sleep_in_shift: context.getters.sleepInShiftAdditional,
            // add_emergency_opp: context.getters.emergencyOpp,
            distance: payload.distance,
        });

        if (response) {
            context.commit("profile/saveShiftDistance", {shiftDistance: payload.distance}, {root: true});
            context.commit("clearStoreGetters");
            return true;
        }
    },
    async getAvailableShift(){
        return await Http.get('shift/available');
    },
    async bookShift(context, payload){
        return await Http.post(`shift/${payload.shiftId}/book`);
    },
}