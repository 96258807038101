import { createApp, toRefs, reactive } from 'vue'

import App from './App.vue'
import router from './router';
import store from './store';

import { IonicVue } from '@ionic/vue';
import axios from 'axios'
import VueAxios from 'vue-axios'
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import MenuLayout from './component/MenuLayout.vue';
import ErrorModal from './component/ErrorModal.vue';
import Vue3TouchEvents from "vue3-touch-events";
import VCalendar from 'v-calendar';
import VeeValidatePlugin from './includes/validation';
import VueCookies from 'vue-cookies';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Vue Calendar */
//https://github.com/nathanreyes/v-calendar/tree/v3.0.0-alpha.8
import 'v-calendar/dist/style.css';

/* Vue Moment */
//https://github.com/unstabler/vue-moment-v3
import VueMoment from "vue-moment-v3";

/* Gettext */
import { createGettext } from "vue3-gettext";
//import translations from "./language/translations.json";

/* Theme variables */
import './theme/css/fontawesome.css';
import './theme/css/scss/variables.scss';
import './theme/css/scss/style.scss';
import './theme/css/scss/icons.scss';
import './theme/css/scss/all.scss';

import './registerServiceWorker'

import {PushNotifications} from '@capacitor/push-notifications';

// use hook after platform dom ready
GoogleAuth.initialize({
  clientId: '128320243285-13c7sfct355nmvrrsgf05jj23avp6mtq.apps.googleusercontent.com',
  scopes: ['profile', 'email'],
  grantOfflineAccess: true,
});

//Gettext
const gettext = createGettext({
  availableLanguages: { en: "English" },
  defaultLanguage: "en",
  mutedLanguages: [],
  silent: true,   //Set to tru to hide errors
  //translations: translations,
  setGlobalProperties: true,
  provideDirective: true,
  provideComponent: true,
});

const app = createApp(App)
  .use(toRefs)
  .use(reactive)
  .use(IonicVue)
  .use(router)
  .use(store)
  .use(GoogleAuth)
  .use(VueAxios, axios)
  .use(gettext)
  .use(VueMoment)
  .use(Vue3TouchEvents)
  .use(VCalendar, {
    componentPrefix: 'vc',
  })
  .use(VeeValidatePlugin, { foo: 5 }) // Our custom plugin
  .use(VueCookies);

//Use `menu-layout`
app.component('menu-layout', MenuLayout);

//Use `error-modal`
//"welcome-screen": WelcomeScreen,
app.component('error-modal', ErrorModal);

//Pluralize filter: https://gist.github.com/ndabAP/d7a338407bb22794418bc64875af14ee


router.isReady().then(() => {
  app.mount('#app');
});

PushNotifications.requestPermissions().then(result => {
  if (result.receive === 'granted') {
    PushNotifications.register();
  }
});

PushNotifications.addListener('registration', token => {
  localStorage.setItem('fcmToken', token.value);
});

/*PushNotifications.addListener('pushNotificationReceived', notification => {
  console.log('Push received: ' + JSON.stringify(notification));
});*/
