export default {
    setDashboardStatus(state, payload) {
        state.dbsCheckAdult = payload.dbsCheckAdult;
        state.dbsCheckChild = payload.dbsCheckChild;
        state.dbsRisk = payload.dbsRisk;
        state.healthRisk = payload.healthRisk;
        state.rightToWork = payload.rightToWork;
        state.idBadge = payload.idBadge;
        state.successfulInterview = payload.successfulInterview;
        state.references = payload.references;
        state.dashboardPoints = payload.dashboardPoints;
        state.referralPoint = payload.referralPoint;
        state.practicalPoint = payload.practicalPoint;
        state.onlineTrainingPoint = payload.onlineTrainingPoint;
        state.onlineTrainingStatus = payload.onlineTrainingStatus;
        state.shiftDistance = payload.shiftDistance;
        state.isSirenumCompliant = payload.isSirenumCompliant;
    },
    savePracticalLocation(state, payload){
        state.practicalLocation = payload.location;
        state.practicalLocationId = payload.locationId;
    },
    savePracticalDate(state, payload){
        state.practicalDateId = payload.selectedDateId;
        state.practicalDate = payload.selectedDate
    },
    saveShiftDistance(state, payload){
        state.shiftDistance = payload.shiftDistance;
    },
};