//Import Vue Cookies
import VueCookies from 'vue-cookies';

//Import the Capcitor Storage
import { Storage } from '@capacitor/storage';
import { Device } from '@capacitor/device';

//import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
//import { FacebookLogin } from '@capacitor-community/facebook-login';

import Http from '/src/lib/http';

export default {
    //Login normal - dispatches auth() - mode: Login, Type: Normal
    async login(context, payload) {
        return context.dispatch('auth', {
            ...payload,
            mode: 'login',
            loginType: 'normal'
        });
    },

    //Login social - dispatches auth() - mode: Signup
    async signup(context, payload) {
        return context.dispatch('auth', {
            ...payload,
            mode: 'signup',
        });
    },

    //Login social - dispatches auth() - mode: Login
    async loginsocial(context, payload) {
        return context.dispatch('auth', {
            ...payload,
            mode: 'login',
        });
    },

    //Auth
    async auth(context, payload) {
        //Mode and login type
        const mode = payload.mode;
        const datatype = payload.loginType;

        //Setup vars
        let data = '';
        let endpoint = '';


        let deviceName = 'unknown';

        try {
            const info = await Device.getInfo();

            if (info.name !== undefined) {
                deviceName = info.name;
            } else {
                deviceName = `${info.manufacturer} ${info.model}`;
            }
        } catch (err) {
            console.error(`Failed to retrieve device info: ${err.message}`);
        }

        //If `signup`
        if (mode == 'signup') {
            //Use URL:
            endpoint = 'auth/register';

            //If data type if `normal`
            data = (datatype == 'normal') ? {
                first_name: payload.firstName,
                last_name: payload.lastName,
                email: payload.email,
                mobile_number: payload.mobile,
                password: payload.password,
                referral_code: payload.referralCode,
                device_name: deviceName,

            //Else
            } : {
                first_name: payload.firstName,
                last_name: payload.lastName,
                email: payload.email,
                auth_provider: datatype,
                mobile_number: payload.mobile,
                referral_code: payload.referralCode,
                device_name: deviceName,
            };

        //If `login`
        } else if (mode == 'login') {
            //If data type if `normal`
            if (datatype == 'normal') {
                endpoint = 'auth/login';

                data = {
                    mobile_number: payload.mobile,
                    password: payload.password,
                    device_name: deviceName,
                };
            } else {
                endpoint = 'auth/social-login';

                data = {
                    auth_provider: datatype,
                    token: payload.token,
                    id: payload.id,
                    device_name: deviceName,
                };
            }
        }

        const fcmToken = localStorage.getItem('fcmToken');

        if (fcmToken) {
            data.fcm_token = fcmToken;
        }

        const responseData = await Http.post(endpoint, data);

        await Storage.set({key: 'token', value: responseData.token});

        Http.setToken(responseData.token);

        //Set data
        await context.dispatch('setUserData', responseData.user, { root: true });
    },
    async forgotpassword(context, payload) {
        await Http.post('auth/forgot-password', {
            mobile_number: payload.mobile,
        });
    },
    async resetpassword(context, payload) {
        await Http.post('auth/reset-password', {
            otp: payload.otp,
            password: payload.password,
        });
    },
    async verifyotp(context, payload) {
        await Http.post('auth/verify-otp', {
            otp: payload.enteredOtp,
        });

        await context.dispatch('profile/getUserData', null, { root: true });
    },

    async resendotp(context) {
        const mobileNo = context.getters.mobileNo;

        return await Http.post('auth/send-otp', {
            mobile_number: mobileNo,
        });
    },
    async storeReferralCode(context, payload) {
        const response = await Http.get('auth/verify-referral-code', {
            referral_code: payload.referralCode,
        });

        if (response) {
            context.commit('storeReferralCode', {
                referralCode: payload.referralCode
            });
        }
    },

    //Set user data
    async setUserData(context, payload) {
        context.commit('setUser', payload);
    },

    //Logout
    async logout(context) {
        //Default user variables
        context.commit('resetUser');
        
        //Logout from Google Logins
        //await GoogleAuth.signOut(null); //This may not be a valid call - I could not see anything in the docs for SignOut

        //Logout from Facebook Logins
        //await FacebookLogin.logout(); - Requires an Access Token, how do we get this?

        //Remove token storage
        await Storage.remove({
            key: 'token'
        });

        //Welcome screens cookie
        if (!VueCookies.isKey('hideWelcome')) {
            //Remove the `hideWelcome` cookie
            //VueCookies.remove('hideWelcome');
        }
    },
    async getFacebookData(context, payload) {
        return await Http.get('auth/facebook-data', {
            facebook_user_id: payload.userId,
            facebook_token: payload.token,
        });
    },
};