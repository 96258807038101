import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { Storage } from '@capacitor/storage';

import authModule from './modules/auth/index';
import completeProfile from './modules/completeprofile/index';
import profileModule from './modules/profile/index';
import rewardsModule from './modules/rewards/index';
import shiftModule from './modules/shift/index';

const store = createStore({
    modules: {
        auth: authModule,
        cmprof: completeProfile,
        profile: profileModule,
        rewards: rewardsModule,
        shifts: shiftModule,
    },
    plugins: [createPersistedState({
        storage: window.localStorage || {
            getItem: async (key) => {
                const result = await Storage.get({ key });

                return result.value;
            },
            setItem: async (key, value) => Storage.set({ key, value }),
            removeItem: async (key) => Storage.remove({ key }),
        },
    })],
});

export default store;