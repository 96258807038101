export default {
    dbsCheckAdult(state) {
        return state.dbsCheckAdult;
    },
    dbsCheckChild(state) {
        return state.dbsCheckChild;
    },
    dbsRisk(state) {
        return state.dbsRisk;
    },
    healthRisk(state) {
        return state.healthRisk;
    },
    rightToWork(state) {
        return state.rightToWork;
    },
    idBadge(state) {
        return state.idBadge;
    },
    successfulInterview(state) {
        return state.successfulInterview;
    },
    references(state) {
        return state.references;
    },
    allStatusesValid(state) {
        return state.dbsCheckAdult
            && state.dbsCheckChild
            && state.dbsRisk
            && state.healthRisk
            && state.rightToWork
            && state.idBadge
            && state.successfulInterview
            && state.references;
    },
    canViewAllDetails(state) {
        return state.dbsCheckAdult
            && state.dbsRisk
            && state.healthRisk
            && state.rightToWork
            && state.idBadge
            && state.successfulInterview
            && state.references;
    },
    isSirenumCompliant(state) {
        return state.isSirenumCompliant;
    },
    practicalLocation(state) {
        return state.practicalLocation ?? '';
    },
    practicalLocationId(state) {
        return state.practicalLocationId ?? 0;
    },
    practicalDate(state) {
        return state.practicalDate ?? '';
    },
    practicalDateId(state) {
        return state.practicalDateId ?? 0;
    },
    referralPoint(state) {
        return state.referralPoint ?? 0;
    },
    dashboardPoints(state) {
        return state.dashboardPoints ?? 0;
    },
    practicalPoint(state) {
        return state.practicalPoint ?? 0;
    },
    onlineTrainingPoint(state) {
        return state.onlineTrainingPoint ?? 0;
    },
    onlineTrainingStatus(state) {
        return state.onlineTrainingStatus;
    },
    shiftDistance(state) {
        return state.shiftDistance;
    },
};