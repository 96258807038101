import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
    namespaced: true,
    state() {
        return {
            dbsCheckAdult: false,
            dbsCheckChild: false,
            dbsRisk: false,
            healthRisk: false,
            rightToWork: false,
            idBadge: false,
            successfulInterview: false,
            references: false,
            practicalLocation: '',
            practicalLocationId: 0,
            practicalDate: '',
            practicalDateId: 0,
            shiftDistance: null,
            isSirenumCompliant: false,
        };
    },
    mutations,
    actions,
    getters,
}