export default {
    id(state){
        return state.id;
    },
    code(state){
        return state.code;
    },
    token(state){
        return state.token;
    },
    firstName(state){
        return state.firstName;
    },
    lastName(state){
        return state.lastName;
    },
    mobileNumber(state){
        return state.mobileNumber;
    },
    email(state){
        return state.email;
    },
    street(state){
        return state.street;
    },
    town(state){
        return state.town;
    },
    county(state){
        return state.county;
    },
    postcode(state){
        return state.postcode;
    },
    image(state){
        return state.image;
    },
    verified(state){
        return state.verified;
    },
    isAddressComplete(state){
        return !!state.street && !!state.town && !!state.postcode;
    },
    isAuthenticated(state){
        return state.id > 0;
    },
    isVerified(state){
        return !!state.verified;
    },
    isImageProvided(state){
        return !!state.image;
    },
    referralCode(state){
        return state.referralCode ?? '';
    },
    coinsBalance(state){
        return state.coinsBalance ?? 0;
    },
    coinsPending(state){
        return state.coinsPending ?? 0;
    },
    notificationsEmail(state){
        return state.notificationsEmail;
    },
    notificationsSms(state){
        return state.notificationsSms;
    },
    notificationsPush(state){
        return state.notificationsPush;
    },
};