
<template>
  <ion-page>
    <ion-loading
      :is-open="showLoader"
      cssClass="loading-default"
      message="Please wait..."
    >
    </ion-loading>

    <!-- Custom error modal -->
    <error-modal
      :errorArgs="errorModalArgs"
    >
    </error-modal>

    <ion-content fullscreen class="ion-no-padding home">
      <section class="container complete-profile-manual">
        <div class="welcome-heading">
          <h2>Welcome to</h2>
          <h3>Temp Rewards</h3>
          <h4>The only app where you can...</h4>
          <ul class="bullet-text-home">
            <li>
              <i
                ><img
                  src="../../theme/images/bullet-icon.svg"
                  alt="TempRewards"
              /></i>
              See the latest jobs in your area, first!
            </li>
            <li>
              <i
                ><img
                  src="../../theme/images/bullet-icon.svg"
                  alt="TempRewards"
              /></i>
              Earn TempCoins on top of your salary!
            </li>
            <li>
              <i
                ><img
                  src="../../theme/images/bullet-icon.svg"
                  alt="TempRewards"
              /></i>
              Be part of a Temp Community!
            </li>
          </ul>
        </div>
        <div class="complete-grid-outr">
          <div class="row">
            <div class="col-sm-8 complete-text-col">
              <div class="complete-text-outr">
                <p class="complete-txt">
                  Complete your registration and earn {{ this.coinsList['complete_initial_registration'] }} TempCoins
                </p>
<!--                <p class="temp-text">250 TempCoins = <br />A Coffee on us!</p>
                <p class="offer-text">*offer expires 31st May 2022</p>-->
              </div>
            </div>
            <div class="col-sm-4 complete-img-col">
              <div class="complete-img-outr">
                <img
                  src="../../theme/images/cofee-pic.svg"
                  alt="TempRewards"
                  width="100%"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </ion-content>
    <ion-footer :class="{'hasScrollbar':  hasScrollbar, 'ref-form-open': referralFormVisible }">
      <div class="did-txt-outr">
        <p v-if="!referralFormVisible">
          Did a friend tell you about Temp Rewards? Enter their referral code
          <span><a v-on:click="openContent()">here</a></span>
        </p>
        <div class="ref-form-outr" v-show="referralFormVisible">
          <form class="ref-form" v-on:submit.prevent="storeReferralCode">
            <label>Referral Code</label>
            <input
              class="form-control"
              type="text"
              v-model="referralCode"
              v-on:input="checkBlank"
            />
            <button class="btn ref-btn" type="button" v-if="isBlank">
              Submit
              <i class="pull-right pad-0"
                ><img src="../../theme/images/submit-gray-arrow.svg"
              /></i>
            </button>
            <button class="btn ref-btn green-btn" type="submit" v-if="!isBlank">
              Submit
              <i class="pull-right pad-0"
                ><img src="../../theme/images/submit-white-arrow.svg"
              /></i>
            </button>
          </form>
        </div>
      </div>
      <div class="skip-sec-outr">
        <a v-on:click="goToPage">Skip</a>
        <ul class="pagination-mnu">
          <RouterLink to="/home"><li class="active"></li></RouterLink>
          <RouterLink to="/what-are-temp-coins"><li></li></RouterLink>
          <RouterLink to="/getting-started"><li></li></RouterLink>
        </ul>
        <RouterLink to="/what-are-temp-coins">Next</RouterLink>
      </div>
    </ion-footer>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonFooter, IonLoading, alertController } from "@ionic/vue";

export default {
  components: {
    IonPage,
    IonContent,
    IonFooter,
    IonLoading,
  },
  data() {
    return {
      referralCode: "",
      isBlank: true,
      showLoader: false,
      errorModalArgs: [],
      coinsList: {},
      referralFormVisible: false
    };
  },

  async ionViewWillEnter() {
    this.coinsList = await this.$store.dispatch("profile/getTempCoins");
  },
  
  //Ion View Did enter
  async ionViewDidEnter() {
  },

  computed: {
    isLoggedId() {
      return this.$store.getters.isAuthenticated;
    },
    isVerified() {
      return this.$store.getters.isVerified;
    },
    isAddress() {
      return this.$store.getters.isAddressComplete;
    },
    isImage() {
      return this.$store.getters.isImageProvided;
    },
  },
  methods: {
    openContent: function () {
      this.referralFormVisible = !this.referralFormVisible;
    },
    checkBlank() {
      this.isBlank = true;
      if (this.referralCode.length === 4) {
        this.isBlank = false;
      }
    },
    async storeReferralCode() {
      this.showLoader = true;
      try {
        await this.$store.dispatch("storeReferralCode", {
          referralCode: this.referralCode,
        });

        await this.$router.push("/what-are-temp-coins");
      } catch (error) {
        alertController
          .create({
            header: "Sorry!",
            message:
              "Please enter a valid referral code. The code you have entered does not exist.",
            buttons: ["OK"],
            class: "alert-custom",
          })
          .then((a) => a.present());
      }

      this.showLoader = false;
    },
    goToPage() {
      this.$router.push({ name: 'dashboard' }) //Go to the dashboard
    },
    hasScrollbar() {
        document.querySelector(".content-ltr").hasScrollBar = function() {
            return this.get(0).scrollHeight > this.height();
        }
    }
  },
};
</script>

<style>
.pagination-mnu li {
  margin: 0px 5px;
}
.content-ltr.home {
	--offset-bottom: 151px !important;
}
@media (min-width: 768px) {
  .home .complete-img-outr img {
      object-fit: cover;
      border-top-right-radius: 10px;
      max-height: calc(100vh - 479px)!important;
  }
}
</style>