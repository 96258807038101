<template>
	<section class="dashboard-overlay dashboard-overlay-scroll" v-if="computedArgs.show">
		<div class="
			overlay-content-wrapper 
			dashboard-12-content-outr dashboard-13-content-outr
			listing-overlay-content-outr
			custom-error-item
		">
			
			<!-- Close Icon -->
			<div class="close-icon">
				<a v-on:click="close"><i><img src="../theme/images/close-icon-black.svg" /></i></a>
			</div>

			<!-- Title 
			<div class="list-overlay-heading">
				<h3>
					<template v-if="computedArgs.title">{{ computedArgs.title }}</template>
					<template v-else>Sorry!<br>There was a problem.</template>
				</h3>
			</div>
			-->

			<!-- Test 
			<div class="core-content-outr" v-if="computedArgs.message">
				<div class="core-content-mnu-outr">
				</div>
			</div>
			-->

			<!-- Content -->
			<div class="listing-overlay-description">
				<h3>
					<template v-if="computedArgs.title">{{ computedArgs.title }}</template>
					<template v-else>Sorry!<br>There was a problem.</template>
				</h3>

				<div v-if="computedArgs.message" v-html="computedArgs.message"></div>

				<a class="btn ref-btn" v-on:click="close">
					Close <i class="fa-solid fa-arrow-right pull-right"></i>
				</a>
			</div>
			
		</div>
	</section>
</template>
  
<script>
  export default {
	//Components
	components: {
	},
	
	//Props
	props: { 
		//`errorArgs` should be an array
		errorArgs: {
			type: Array,// if this is an array or use type: Object if that's an object
			default: null
		}
	},

	watch: {
		errorArgs(newVal, OldVal) {
			console.log("Old");
			console.log(OldVal);
			console.log("New");
			console.log(newVal);
		}
	},

	computed: {
		computedArgs() {
			return this.errorArgs
		}
	},

	//Data
	data() {
		return {
		};
	},

	//Methods
	methods: {
		close() {
			this.computedArgs.show = false;
		},
	},
  }
</script>